import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Action } from '@priva/components/actions';
import { PrivaContentHeaderModule } from '@priva/components/content-header';
import { PrivaHeaderModule } from '@priva/components/header';
import { PrivaTitleSizeType } from '@priva/components/title';
import { PrivaTranslateModule } from '@priva/localization';
import { PrivaThemeType } from '@priva/styles/foundation';

@Component({
    selector: 'analytics-status-error-page',
    standalone: true,
    imports: [PrivaTranslateModule, PrivaHeaderModule, PrivaContentHeaderModule],
    templateUrl: 'status-error-page.component.html',
})
export class StatusErrorPageComponent {
    @Input() public title: string;
    @Input() public info: string;
    @Input() public extraInfo: string;
    @Input() public actions: Action[];

    @Input() public actionTitle: string;
    @Input() public actionHtmlId: string;
    @Input() public actionHtmlClass: string;
    @Input() public actionIcon: string;
    @Output() public actionClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    public isExpanded = false;
    public privaTitleSize: PrivaTitleSizeType = 'lg';
    public privaTheme: PrivaThemeType = 'onlightgray';

    public get hasExtraInfo(): boolean {
        return !!this.extraInfo;
    }

    public actionButtonClick() {
        this.actionClicked.emit(true);
    }
}
